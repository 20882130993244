// eslint-disable-next-line no-redeclare,no-unused-vars
function checkWhereValue(object, row, key, value, nullAsEmpty, level = 1) {
  if (level >= 10) return null;
  if (!row) row = {};
  var success = true;
  var param = {};

  // key value
  var keyValue = '';
  if (inArray(key, calcVars) || str_contains(key, '.'))
    keyValue = calculate(key, {}, row, {object: object});
  else keyValue = row[key];

  // value
  if (key == '$or') param = {or: value};
  else if (isPlainObject(value)) {
    param = value;
    value = value.value;
  }
  if (inArray(value, calcVars)) value = calculate(value);
  if (value == '-') value = '';
  else if (value == '!') {
    value = '';
    param.not = true;
  }

  // if (value != '*') {
  // 	if (isString(value)) value = calculate(value, getObjectValue(fields, [object, key]));
  // 	if (getObjectValue(fields, [object, key, 'number']) && isString(value) && !isNaN(value)) value = parseInt(value);
  // }

  if (value == '*') success = true;
  else if (param.or) {
    if (isPlainObject(param.or))
      $.each(param.or, function (k, val) {
        success = checkWhereValue(object, row, k, val, nullAsEmpty, level + 1);
        if (success) return false;
      });
    else
      $.each(param.or, function (i, val) {
        success = checkWhereValue(
          object,
          row,
          key,
          val,
          nullAsEmpty,
          level + 1
        );
        if (success) return false;
      });
  } else if (param.and) {
    if (isPlainObject(param.and))
      $.each(param.and, function (k, val) {
        success = checkWhereValue(object, row, k, val, nullAsEmpty, level + 1);
        if (!success) return false;
      });
    else
      $.each(param.and, function (i, val) {
        success = checkWhereValue(
          object,
          row,
          key,
          val,
          nullAsEmpty,
          level + 1
        );
        if (!success) return false;
      });
  } else if (keyValue === undefined && !nullAsEmpty) success = null;
  else if (param.compare)
    success = eval('"' + keyValue + '" ' + param.compare + ' "' + value + '"');
  // else if (str_contains(key, '.')) success = calculate(key, {}, row) == value;
  else if (isArray(value) && isArray(keyValue)) {
    var values = arrayMerge(value, keyValue);
    if (values.length > arrayUnique(values).length) success = true;
    else success = false;
  } else if (isArray(value)) {
    if (inArray(keyValue, value) || (!value.length && !keyValue))
      success = true;
    else success = false;
  } else if (isArray(keyValue)) {
    if (inArray(value, keyValue) || (!value && !keyValue.length))
      success = true;
    else success = false;
  } else if (
    value &&
    param.regexp &&
    keyValue &&
    keyValue.match(new RegExp(escapeRegExp(value)))
  )
    success = true;
  else if (
    value &&
    getObjectValue(fields, [object, key, 'date']) &&
    value.length <= 10 &&
    keyValue &&
    keyValue.indexOf(value) > -1
  )
    success = true;
  else if (keyValue == value) success = true;
  else if (!value && !keyValue) success = true;
  // 0 == null here in where filter
  else success = false;

  if (param.not) {
    if (success) success = false;
    else success = true;
  }

  // if (typeof testId !== 'undefined') testingOutput('checkWhereValue',success,testId);
  return success;
}
